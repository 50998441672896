<template>
  <div>
    <!-- 卡片视图区域 -->
    <el-card>
      <el-row>
        <div class="input">
          <!-- 输入框，尾部带按钮 -->
          用户账号：
          <el-input
            placeholder="请输入用户手机号"
            v-model="queryInfo.mobile"
            clearable
            @clear="getFeedbackList"
            @change="searchCoupon"
          >
            <el-button
              slot="append"
              @click="searchCoupon"
            >搜索</el-button>
          </el-input>
        </div>
        <div class="input">
          反馈来源：
          <el-select
            v-model="queryInfo.source"
            placeholder="请选择来源"
            clearable
            @clear="getFeedbackList"
            @change="searchCoupon"
          >
            <el-option
              v-for="item in sourceList"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </div>
        <div class="input">
          状态：
          <el-select
            v-model="queryInfo.status"
            placeholder="请选择状态"
            clearable
            @clear="getFeedbackList"
            @change="searchCoupon"
          >
            <el-option
              v-for="item in statusList"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </div>
      </el-row>
      <!-- 内容主体 -->
      <el-table
        :data="feedbackList"
        stripe
        border
      >
        <!-- 第一列id -->
        <el-table-column
          label="id"
          width="100"
          aligin="center"
          prop="id"
        >
        </el-table-column>
        <!-- 第二列手机号 -->
        <el-table-column
          label="用户手机号"
          aligin="center"
          prop="mobile"
        >
        </el-table-column>
        <!-- 第三列反馈内容 -->
        <el-table-column
          label="反馈内容"
          width="300"
          aligin="center"
        >
          <template slot-scope="scope">
            <el-tooltip
              :content="scope.row.content"
              placement="bottom"
              effect="light"
            >
              <p>{{ scope.row.content }}</p>
            </el-tooltip>
          </template>
        </el-table-column>
        <!-- 第四列配图 -->
        <el-table-column
          label="配图"
          aligin="center"
        >
          <template slot-scope="scope">
            <el-popover
              trigger="hover"
              title=""
              placement="bottom"
              v-if="scope.row.feedBackFile"
            >
              <img
                :src="scope.row.feedBackFile[0]"
                width="200"
                height="200"
              />
              <img
                slot="reference"
                :src="scope.row.feedBackFile[0]"
                style="height: 80px;width: 80px"
              />
            </el-popover>
          </template>
        </el-table-column>
        <el-table-column
          label="反馈来源"
          aligin="center"
          prop="source"
        >
          <template slot-scope="scope">
            <div v-if="scope.row.source === 0">
              消费者
            </div>
            <div v-else>
              商家端
            </div>
          </template>
        </el-table-column>
        <!-- 第五列状态 -->
        <el-table-column
          label="状态"
          aligin="center"
          prop="status"
        >
          <template slot-scope="scope">
            <div v-if="scope.row.status === 0">
              未处理
            </div>
            <div v-else>
              已处理
            </div>
          </template>
        </el-table-column>
        <!-- 第六列创建时间 -->
        <el-table-column
          label="创建时间"
          width="200"
          aligin="center"
          prop="createTime"
        >
        </el-table-column>
        <!-- 第七列操作 -->
        <el-table-column
          label="操作"
          width="200"
          aligin="center"
        >
          <template slot-scope="scope">
            <!-- 回复按钮 -->
            <el-button
              size="mini"
              type="primary"
              @click="showReviewDialog(scope.row.id)"
              v-if="scope.row.status === 0"
            >回复</el-button>
            <el-button
              size="mini"
              type="primary"
              @click="showReviewDialog(scope.row.id)"
              v-else
              disabled
            >回复</el-button>
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页 -->
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="queryInfo.pageNo"
        :page-sizes="[15, 30, 50, 100]"
        :page-size="queryInfo.limit"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </el-card>
    <!-- 新增对话框 -->
    <el-dialog
      :title="title"
      :visible.sync="reviewDialogVisiable"
      width="600px"
      @close="clearAddInfo"
    >
      <div class="review">
        <!-- 第一行 -->
        <!-- <div class="review-firstrow"> -->
        <!-- <div>用户账号：{{ feedbackDetail.mobile }}</div> -->
        <!-- <div>
  提交时间：{{feedbackDetail.mobile}}
</div> -->
        <!-- </div> -->
        <!-- 第二行 -->
        <div class="review-firstrow">
          反馈内容：{{ feedbackDetail.content }}
        </div>
        <!-- 第三行 -->
        <div
          class="review-thirdrow"
          v-if="feedbackDetail.feedBackFile"
        >
          反馈图片：
          <div
            v-for="(item, index) in feedbackDetail.feedBackFile"
            :key="index"
            class="img"
          >
            <img
              :src="item"
              alt=""
            />
          </div>
        </div>
        <!-- 第四行 -->
        <div class="review-thirdrow">
          <div>
            回复内容：
          </div>
          <el-input
            v-model="reply"
            type="textarea"
            :rows="5"
            placeholder="请输入回复内容"
            class="select"
          ></el-input>
        </div>
      </div>
      <span
        slot="footer"
        class="dialog-footer"
      >
        <el-button @click="reviewDialogVisiable = false">取 消</el-button>
        <el-button
          type="primary"
          @click="submit"
        >提 交</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data () {
    return {
      // 反馈列表请求入参
      queryInfo: {
        pageNo: 1,
        limit: 15,
        mobile: '',
        status: '',
        source: '' // 反馈来源 0-消费者,1-商户端
      },
      // 反馈列表
      feedbackList: [],
      // 总数
      total: 0,
      // 状态数组
      statusList: [
        {
          id: '0',
          name: '未处理'
        },
        {
          id: '1',
          name: '已处理'
        }
      ],
      // 来源数组
      sourceList: [
        {
          id: '0',
          name: '消费者'
        },
        {
          id: '1',
          name: '商家端'
        }
      ],
      // 回复
      reviewDialogVisiable: false,
      title: '',
      // 详情id
      id: '',
      // 详情对象
      feedbackDetail: {},
      // 回复内容
      reply: ''
    }
  },
  created () {
    // 请求反馈列表
    this.getFeedbackList()
  },
  methods: {
    searchCoupon () {
      this.queryInfo.pageNo = 1
      this.getFeedbackList()
    },
    // 定义请求反馈列表方法
    async getFeedbackList () {
      const { data: res } = await this.$http.get('agriculturalFeedback/list', {
        params: this.queryInfo
      })
      if (res.code !== 0) {
        return this.$message.error('查询反馈列表失败')
      }
      // 赋值
      this.feedbackList = res.data.data
      this.total = res.data.total
    },
    // 反馈详情
    async getFeedbackDetail () {
      const { data: res } = await this.$http.get(
        `agriculturalFeedback/${this.id}`
      )
      if (res.code !== 0) {
        return this.$message.error('查询反馈详情失败')
      }
      this.feedbackDetail = res.data
      console.log(this.feedbackDetail)
    },
    // 回复
    showReviewDialog (id) {
      // 把id赋值给上面详情接口需要用到的参数
      this.id = id
      // 请求详情接口
      this.getFeedbackDetail()
      // title
      this.title = '回复'
      // 打开对话框
      this.reviewDialogVisiable = true
    },
    // limit变化，调用列表接口
    handleSizeChange (newSize) {
      this.queryInfo.limit = newSize
      this.getFeedbackList()
    },
    // 页码变化，调用接口
    handleCurrentChange (newPage) {
      this.queryInfo.pageNo = newPage
      this.getFeedbackList()
    },
    // 关闭对话框
    clearAddInfo () { },
    // 提交反馈
    async submit () {
      const { data: res } = await this.$http.put(
        `agriculturalFeedback/reply/${this.id}?reply=${this.reply}`
      )
      if (res.code !== 0) {
        return this.$message.error('回复失败')
      }
      this.$message.success('回复成功')
      this.getFeedbackList()
      this.reviewDialogVisiable = false
    }
  }
}
</script>

<style lang="less" scoped>
.el-row {
  display: flex;
}
.input {
  display: flex;
  width: 320px;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
}
.input:last-child {
  margin-left: -30px;
}
.el-select {
  width: 180px;
}
.el-input {
  width: 240px;
}
.review-firstrow {
  display: flex;
  justify-content: space-between;
  height: 40px;
  line-height: 40px;
  // margin-bottom: 20px;
}
.review-thirdrow {
  margin-top: 20px;
  display: flex;
}
.img {
  width: 120px;
  height: 120px;
  margin-right: 10px;
}
.img img {
  width: 120px;
  height: 120px;
}
.select {
  width: 460px;
}
p {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
p:hover {
  cursor: Default;
}
</style>
